<template>
  <div class="game-controls">
    <button @click="startGame()" v-show="$store.getters.ready">Démarrer</button>
    <div v-show="$store.getters.inLearning">
      <button @click="giveResponse()" v-show="$store.getters.listening">La réponse...</button>

      <button @click="sendResult('success')" v-show="$store.getters.response">
        J'ai bon
      </button>
      <button @click="sendResult('fail')" v-show="$store.getters.response">
        Nupe :/
      </button>
      <button @click="sendResult('skip')" v-show="$store.getters.response">
        Heu, enlève le
      </button>

      <button @click="pauseMusic($event)" v-show="audioOn">Pause</button>
      <button @click="playMusic($event)" v-show="!audioOn">Reprendre</button>
    </div>
    <button @click="leaveGame()">Changer de playlist</button>
  </div>
</template>

<script>
export default {
  name: "GameControls",
  methods: {
    startGame: function () {
      this.$store.dispatch("giveSong");
    },
    leaveGame: function () {
      //this.pauseMusic();
      this.$store.dispatch("leaveGame");
    },
    giveResponse: function () {
      this.$store.dispatch("giveResponse");
    },
    sendResult: function (result) {
      this.$store.dispatch('sendResult', result)
    },
    pauseMusic: function () {
      this.$store.dispatch("changeAudioStatus", false);
    },
    playMusic: function () {
      this.$store.dispatch("changeAudioStatus", true);
    },
  },
  computed: {
    audioOn: function () {
      return this.$store.getters.audioOn;
    },
  },
}
</script>

<style scoped>
button {
  min-width: 100px;
}

</style>