<template>
  <div>
    <div v-show="!$store.getters.inGame">
      <label for="playlist-input">Id / url d'une playlist</label>
      <input v-model="playlistUrl" id="playlist-input">
    </div>
    <div class="plyalistlist">
      <div
          v-for="playlist in playlists"
          :key="playlist.spotifyId"
          @click="selectPlaylist(playlist)"
          class="playlist-block"
      >
        <img class="cover" :src="playlist.coverUrl">
        <button>{{ playlist.name }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlaylistSelector",
  data: function () {
    return {
      playlistUrl: "",
    }
  },
  methods: {
    selectPlaylist: function (playlist) {
      this.$store.dispatch("choosePlaylist", playlist);
    },
  },
  computed: {
    playlists: function () {
      return this.$store.getters.allPlaylists;
    },
  },
  watch: {
    playlistUrl: function (newPlaylist) {
      this.$store.dispatch("addPlaylist", newPlaylist);
    },
  },
}

</script>

<style scoped>
.plyalistlist {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.playlist-block {
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;

  cursor: pointer;
}

button {
  width: 100%;
}

.cover {
  width: 100%;
}
</style>