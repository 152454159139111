import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex);

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

// the flow
const StatusEnum = Object.freeze({
  'notIn': 0,
  'noLesson': 1,
  'ready': 2,
  'listening': 3,
  'response': 4,
  'next': 5,
});

const state = {
  // user infos
  token: "",
  user: {
    is_authenticated: false,
    username: "",
    picture: "",
  },
  
  // game states
  gameStatus: StatusEnum.notIn,
  audioOn: false,
  
  // playlist flow
  allPlaylists: [],
  trackPlaylist: [],
  
  selectedPlaylist: Object,
  currentSongIndex: null,
  nextSongIndex: null,
  currentSongInfo: Object,
}

function axiosGetAuth(url, token) {
  return axios.get(url, {
      headers: {
        'Authorization': 'Token ' + token,
      },
    },
  )
}

const getters = {
  token: (state) => {
    return state.token;
  },
  
  inGame: (state) => {
    return state.gameStatus !== StatusEnum.notIn;
  },
  inLearning: (state) => {
    return !(
      state.gameStatus
      in [StatusEnum.notIn, StatusEnum.noLesson, StatusEnum.ready]
    );
  },
  notIn: (state) => {
    return state.gameStatus === StatusEnum.notIn;
  },
  noLesson: (state) => {
    return state.gameStatus === StatusEnum.noLesson;
  },
  ready: (state) => {
    return state.gameStatus === StatusEnum.ready;
  },
  listening: (state) => {
    return state.gameStatus === StatusEnum.listening;
  },
  response: (state) => {
    return state.gameStatus === StatusEnum.response;
  },
  next: (state) => {
    return state.gameStatus === StatusEnum.next;
  },
  withoutAudio: (state) => {
    return state.currentSongInfo.previewUrl == null;
  },
  audioOn: (state) => {
    return state.audioOn;
  },
  
  currentSong: (state) => {
    return state.trackPlaylist[state.currentSongIndex];
  },
  currentSongInfo: (state) => {
    return state.currentSongInfo;
  },
  nextSong: (state) => {
    return state.trackPlaylist[state.nextSongIndex];
  },
  trackRemaining: (state) => {
    return state.trackPlaylist.length;
  },
  
  allPlaylists: (state) => {
    return state.allPlaylists;
  },
  selectedPlaylist: (state) => {
    return state.selectedPlaylist;
  },
}

const actions = {
  getPlaylists({commit, getters}) {
    axiosGetAuth('/provider/playlist/', getters.token)
      .then(response => {
        commit('SET_AVAILABLE_PLAYLIST', response.data);
      });
  },
  addPlaylist({getters, dispatch}, playlistUrl) {
    const cleanPlaylistId = playlistUrl.split('?')[0].split('/').at(-1);
    axiosGetAuth('/provider/playlist/create/' + cleanPlaylistId, getters.token)
      .then(response => {
          if (response.status === 202) {
            dispatch("getPlaylists");
          }
        },
      ).catch(() => {
    });
  },
  choosePlaylist({commit, getters}, playlist) {
    axiosGetAuth('/provider/playlist/learn/' + playlist.spotifyId, getters.token)
      .then(response => {
        commit('SET_PLAYLIST_TO_LEARN', playlist);
        if (response.data.length === 0) {
          commit('UPDATE_GAME_STATUS', StatusEnum.noLesson);
        } else {
          commit('SET_PLAYLIST_TRACKS', response.data);
          commit('UPDATE_GAME_STATUS', StatusEnum.ready);
        }
      });
  },
  giveSong({commit, getters}) {
    if (!getters.nextSong) {
      return;
    }
    
    const trackId = getters.nextSong.track;
    axios.get('/provider/track/' + trackId)
      .then(response => {
        commit('UPDATE_CURRENT_SONG', response.data);
        commit('DECREMENT_SONG_INDEX');
      });
  },
  giveResponse({commit}) {
    commit('UPDATE_GAME_STATUS', StatusEnum.response);
  },
  sendResult({getters, dispatch, commit}, result) {
    commit('UPDATE_GAME_STATUS', StatusEnum.next);
    
    const lnTrackId = getters.currentSong.pk;
    
    if (result === "success" || result === "skip") {
      commit('POP_SONG', lnTrackId);
    } else if (result !== "fail") { // if result none of 3
      return;
    }
    axiosGetAuth(
      '/provider/update-track/' + lnTrackId + "/" + result,
      getters.token,
    ).then(() => {
      dispatch("giveSong");
    });
  },
  leaveGame({commit}) {
    commit('UPDATE_GAME_STATUS', StatusEnum.notIn);
  },
  changeAudioStatus({commit}, newStatus) {
    commit('CHANGE_AUDIO_STATUS', newStatus)
  },
  
  login({commit, dispatch}, tokenInUrl) {
    // try to find a token somewhere
    const token = tokenInUrl ? tokenInUrl : localStorage.getItem('token');
    if (!token) {
      commit("LOG_OUT");
      return;
    }
    
    // validate it
    axios.get('/auth/login/', {
      headers: {
        'Authorization': 'Token ' + token,
      },
    }).then(response => {
      if (response.status === 200) {
        commit("SET_TOKEN", token);
        commit('SET_USER', response.data);
        dispatch("getPlaylists")
      }
    }).catch(error => {
      if (error.response.status === 401) {
        commit("LOG_OUT");
      }
    });
  },
  logout({getters, commit}) {
    commit('CHANGE_AUDIO_STATUS', false);
    commit('UPDATE_GAME_STATUS', StatusEnum.notIn);
    
    axiosGetAuth('/auth/logout/', getters.token);
    localStorage.removeItem('token');
    commit("LOG_OUT");
  },
}

//to handle mutations
const mutations = {
  UPDATE_GAME_STATUS(state, gameState) {
    state.gameStatus = gameState;
  },
  CHANGE_AUDIO_STATUS(state, newStatus) {
    state.audioOn = newStatus;
  },
  
  SET_AVAILABLE_PLAYLIST(state, playlists) {
    state.allPlaylists = playlists;
  },
  
  SET_PLAYLIST_TO_LEARN(state, playlist) {
    state.selectedPlaylist = playlist;
  },
  SET_PLAYLIST_TRACKS(state, tracks) {
    state.trackPlaylist = shuffle(tracks);
    
    state.endOfLearning = false;
    
    state.currentSongIndex = null;
    state.nextSongIndex = state.trackPlaylist.length - 1;
  },
  SET_END_OF_LEARNING(state) {
    state.endOfLearning = true;
  },
  
  UPDATE_CURRENT_SONG(state, songInfo) {
    state.currentSongInfo = songInfo;
    state.gameStatus = StatusEnum.listening;
  },
  DECREMENT_SONG_INDEX(state) {
    state.currentSongIndex = state.nextSongIndex;
    state.nextSongIndex -= 1;
    if (state.nextSongIndex < 0) {
      state.nextSongIndex = state.trackPlaylist.length - 1;
    }
  },
  POP_SONG(state) {
    state.trackPlaylist.splice(state.currentSongIndex, 1);
    
    //if next song index at end, move to the new end index
    const endIndex = state.trackPlaylist.length - 1
    if (state.trackPlaylist.length === 0) {
      state.gameStatus = StatusEnum.noLesson;
    } else if (state.nextSongIndex > endIndex) {
      state.nextSongIndex = endIndex;
    }
  },
  
  SET_USER(state, user) {
    state.user = user;
    state.user.is_authenticated = true;
  },
  SET_TOKEN(state, token) {
    if (token === undefined) {
      return;
    }
    localStorage.setItem('token', token);
    state.token = token;
  },
  LOG_OUT(state) {
    state.user = {is_authenticated: false};
  },
}

//export store module
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
})


function shuffle(array) {
  var currentIndex = array.length, randomIndex;
  
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  
  return array;
}