<template>
  <div id="app">
    <h2>UnBlind</h2>
    <div v-if="user.is_authenticated">
      Salut <img v-show="user.picture" :src="user.picture" height="50px"> {{ user.username }}
      <button @click="$store.dispatch('logout');">Déconnexion</button>
    </div>
    <div v-else>
      <a :href="rootLoginUrl + '/auth/sign-in/'">Join with Spotify</a>
    </div>
    <music-player v-show="user.is_authenticated"></music-player>

    <div class="useless-help">
      <button v-show="!problemDisplay" @click="problemDisplay = true">J'ai un problème</button>
      <p v-show="problemDisplay">Ah dommage</p>
      <button v-show="problemDisplay" @click="problemDisplay = false">Je n'ai plus le problème</button>
    </div>
  </div>
</template>

<script>

import MusicPlayer from "@/components/MusicPlayer";
import axios from "axios";

export default {
  name: 'App',
  components: {
    MusicPlayer,
  },
  data: function () {
    return {
      problemDisplay: false,
    }
  },
  computed: {
    token: function () {
      return this.$store.state.token;
    },
    user: function () {
      return this.$store.state.user;
    },
    rootLoginUrl: function () {
      return axios.defaults.baseURL;
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.useless-help {
  margin-top: 30px;
}

button {
  margin: 5px;
  background-color: white;
  text-decoration: none;
  border: black 1px solid;
  cursor: pointer;
}

button.selected {
  background-color: black;
  color: white;

}

</style>
