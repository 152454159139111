import Vue from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'

Vue.config.productionTip = false

new Vue({
  store: store,
  router: router,
  beforeCreate() {
    this.$store.dispatch('login', this.$route.query.token);
    if (this.$route.query.token !== undefined) {
      this.$router.push(this.$route.path)
    }
  },
  render: h => h(App),
}).$mount('#app')
