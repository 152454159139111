<template>
  <div>
    <playlist-selector
        v-show="!$store.getters.inGame"
    ></playlist-selector>
    <div class="game-block" v-show="$store.getters.inGame">
      <div class="music-player">
        <h3>{{ currentPlaylist.name }}</h3>
        <p v-show="$store.getters.inLearning">{{ $store.getters.trackRemaining }} tracks restants</p>

        <div v-show="$store.getters.noLesson">
          Voilà, la leçon est finie ! Tu peux recommencer mais vas trop vite... #stratégieDeLaTortue.
        </div>

        <div class="song-info" v-show="$store.getters.inLearning">
          <p v-if="$store.getters.listening">???????????????????????????????</p>
          <p v-else-if="$store.getters.withoutAudio">Pas de preview :(</p>
          <p v-else-if="$store.getters.response">
            {{ currentSongInfo.name }} par {{ currentSongInfo.mainArtist }}</p>
          <p v-else>
            .
          </p>
        </div>
      </div>
      <game-controls>

      </game-controls>
    </div>
  </div>
</template>

<script>

import PlaylistSelector from "@/components/PlaylistSelector";
import GameControls from "@/components/GameControls";

export default {
  name: "MusicPlayer",
  components: {GameControls, PlaylistSelector},
  data: function () {
    return {
      audio: new Audio(),
      inPlay: false,

      gameStatus: [
        'notInGame',
        'before',
        'listening',
        'response',
        'toNext',
      ],
      currentGameStatus: 'notInGame',
    }
  },
  computed: {
    currentPlaylist: function () {
      return this.$store.getters.selectedPlaylist;
    },
    currentSongInfo: function () {
      return this.$store.getters.currentSongInfo;
    },
    listening: function () {
      return this.$store.getters.listening;
    },
    inLearning: function () {
      return this.$store.getters.inLearning;
    },
    audioOn: function () {
      return this.$store.getters.audioOn;
    },
  },
  watch: {
    listening: function (newState) {
      if (newState) {
        this.audio.pause();
        this.audio = new Audio(this.$store.getters.currentSongInfo.previewUrl);
        this.audio.play();
        this.$store.dispatch("changeAudioStatus", true);
      }
    },
    inLearning: function (newState) {
      // if leave game
      if (!newState) {
        this.$store.dispatch("changeAudioStatus", false);
      }
    },
    audioOn: function (newState) {
      // if leave game
      if (newState) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
  },
}
</script>

<style scoped>

</style>